import React, { useState, useEffect, useContext } from 'react';
import { Button, Card, Col, Container, Form, Modal, Row, Spinner, } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { GetApp } from '@material-ui/icons';

import DeviceCardHeader from './DeviceCardHeader';
import ThermostatCardBody from './ThermostatCardBody';
import HotClosetCardBody from './HotClosetCardBody';

import { v4 as uuidv4 } from 'uuid'
import { instanceIoT } from 'service/Device';
import { isDeviceAdd } from 'utils/Device';

import { AlertContext } from 'context/alert';
import { InfosDeviceContext } from 'context/device';
import { SupportContext } from 'context/support';

import styles from './DeviceCardConfig.module.scss';
import button from 'assets/Button/button.module.scss';
import { getUUID } from 'service/Api';

/**
 * build device card config
 * @returns {React.Component} `component`
 */
function DeviceCardConfig({ deviceInfos }) {
  const { setErrorAlert, setSuccessAlert, setShowAlert, setMessageAlert } = useContext(AlertContext);
  const {
    power,
    setPower,
    deviceWifi,
    deviceSetPoint,
    tempMin,
    tempMax,
    modifySetPoint,
    setModifySetPoint,
    ledIntensity,
    awayTemp,
    gauging,
    deviceGroup,
    versionInstalledFirmware,
    prog
  } = useContext(InfosDeviceContext)
  const { addDevicesToLocalStorage } = useContext(SupportContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [uuid, setUuid] = useState();
  const [loadingQRCode, setLoadingQRCode] = useState(true);
  const [loadingReboot, setLoadingReboot] = useState(true);

  const [ params ] = useSearchParams();

  const [updatedFirmware, setUpdatedFirmware] = useState(false);

  const [wifiSettings, setWifiSettings] = useState({ssid: "unknown", password: "unknown"})

  const handleQRcodeDownload = () => {
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = params.get('smartlyId');
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  /**
   * Verify if device is thermostat
   * @param {String} id 
   * @returns {boolean} `bool`
   */
  function isThermostat(id){
    if(id.includes('th'))
      return true

    return false
  }

  /**
   * get the device group and verify model and return it
   * @returns {String} `string`
   */
  function returnDeviceModel(){
    switch(deviceGroup){
      case 'wifi-display':
        return 'HF918';

      case 'HF810':
        return 'Hybrid';

      case 'HF810-A':
        return 'Hybrid_A';

      case 'HF900':
        return 'Hybrid';

      case 'HF900-A':
        return 'Hybrid_A';

      default:
        return deviceGroup
    }
  }

  /**
   * get params smartly id and return type
   * @returns {String} `string`
   */
  function returnDeviceType(){
    const smartlyId = params.get('smartlyId');
    if(smartlyId.includes('th'))
      return 'thermostat';

    if(smartlyId.includes('hc'))
      return 'hotcloset';
    
    if(smartlyId.includes('lg'))
      return 'lighting';

    if(smartlyId.includes('sc'))
      return 'smartlycontrol'
  }

  /**
   * build path S3 for get json
   * @returns {String} `string`
   */
  function getPathS3(){
    const type = returnDeviceType();
    const deviceModel = returnDeviceModel();
    const modelSplit = deviceModel.split('_');

    return `https://smartly-fw.s3.amazonaws.com/${type}/${modelSplit[0]}/${deviceModel}.json`;
  }
  
  /**
   * send job to iot core
   */
  async function sendJob(){
    var iot = instanceIoT();

    if(!isDeviceAdd(deviceInfos))
      addDevicesToLocalStorage(deviceInfos.id)

    const uuid = `${params.get('username')}-${uuidv4()}`;
    const path = getPathS3();
    iot.createJob({
      jobId: uuid,
      targets: [`arn:aws:iot:us-east-1:589464758178:thing/${params.get('smartlyId')}`],
      documentSource: path,
      abortConfig: {
        criteriaList: [{
          action: 'CANCEL',
          failureType: 'ALL',
          thresholdPercentage: 10,
          minNumberOfExecutedThings: 1
        }] 
      },
      timeoutConfig: {
        inProgressTimeoutInMinutes: 2
      }      
    }, function(err, data){
      if(err) console.log(err)
    })

    getStatusJob(uuid)
  }

  useEffect(()=> {
    verifyVersionFirmware();
  }, [versionInstalledFirmware])

  /**
   * verify version firmware installed in device
   */
  function verifyVersionFirmware(){
    const type = returnDeviceType();
    const model = returnDeviceModel();
    
    if(type === '' || model === '' || versionInstalledFirmware === undefined){
      return
    }

    const url = getPathS3();

    getJSON(url,
      function(err, data){
        if(err !== null){
          console.log(err)
        } else {
          if(versionInstalledFirmware !== data.version)
            setUpdatedFirmware(true)          
        }
      }
    )    
  }

  /**
   * get json in aws S3
   * @param {String} url 
   * @param {Function} callback
   */
  async function getJSON(url, callback){
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true)
    xhr.responseType = 'json';
    xhr.onload = function() {
      var status = xhr.status;
      if(status === 200){
        callback(null, xhr.response)
      } else {
        callback(status, xhr.response)
      }
    }
    xhr.send();
  }

  /**
   * @param {uuid} jobId 
   */
  async function getStatusJob(jobId){
    window.scrollTo(0,0);

    var iot = instanceIoT();
    var job;
    var idInterval = 
      setInterval(async ()=>{
        job = await iot.describeJob({jobId: jobId})
        
        if(job.job.jobProcessDetails.numberOfSucceededThings > 0){
          setMessageAlert('Firmware atualizado com sucesso');
          setSuccessAlert(true)
          setShowAlert(true)
          clearTimeout(idInterval);
          idInterval = null;
        }
        
        if( job.job.jobProcessDetails.numberOfTimedOutThings > 0 ||
          job.job.jobProcessDetails.numberOfCanceledThings > 0 ||
          job.job.jobProcessDetails.numberOfRejectedThings > 0 ||
          job.job.jobProcessDetails.numberOfFailedThings > 0 ||
          job.job.jobProcessDetails.numberOfRemovedThings > 0 
          ){
          setMessageAlert('Erro ao enviar atualização de firmware');
          setErrorAlert(true);
          setShowAlert(true)
          clearTimeout(idInterval);
          idInterval = null;
        }
    }, 1000)
  }

  function handleReboot() {
    try{
      setLoadingReboot(true)
      deviceInfos.reboot();
    } catch (e) {
      console.error(e);
    }  }

  /**
   * manager configuration and send update 
   */
  async function handleThermostatUpdateConfig(){
    window.scrollTo(0,0); 

    if(!isDeviceAdd(deviceInfos))
      addDevicesToLocalStorage(deviceInfos.id)
    
    deviceInfos.updateSetpoint(modifySetPoint);
    const res = await deviceInfos.updateAllConfig(params.get('userId'), params.get('smartlyId'), {
        ledIntensity: ledIntensity,
        tempRange: [tempMin, tempMax],
        awayTemp: awayTemp,
        manual: prog? 1 : 0
      })
      deviceInfos.updateConfig({
            power,
            gauging
          }
        )

    setShowAlert(true)

    if(res.status === 1) 
      setSuccessAlert(true)
    else
      setErrorAlert(true)
      
    setMessageAlert(res.message)
  }

  async function handleHotClosetUpdateConfig(){
    window.scrollTo(0,0);
    
    if( 
      power === deviceInfos.device.power &&
      modifySetPoint === deviceInfos.device.setPoint             
    ){
      setMessageAlert('Nenhuma informação alterada');
      setErrorAlert(true);
      setShowAlert(true);
      return      
    }   

    if(!isDeviceAdd(deviceInfos))
    addDevicesToLocalStorage(deviceInfos.id)

    const oldInfos = {
      power: power,
      setPoint: deviceSetPoint
    }
    
    deviceInfos.updateConfig({hum_setpoint: modifySetPoint, power: power});

    var count = 0;
    var idInterval = setInterval(async ()=>{ 

      if( 
          oldInfos.power !== deviceInfos.device.power ||
          oldInfos.setPoint !== deviceInfos.device.setPoint             
        ){
          setMessageAlert('Dispositivo atualizado com sucesso');
          setSuccessAlert(true);
          setShowAlert(true)
          clearTimeout(idInterval);
          
        }        
        if(count>5){
          setMessageAlert('Erro ao atualizar o dispositivo');
          setErrorAlert(true);
          setShowAlert(true);
          clearTimeout(idInterval);
        }
      count++
    }, 1000);
  }

  /**
   * get device uuid in DynamoDB 
   */
  async function getUUIDToApi(){
    const res = await getUUID(params.get('deviceId'), params.get('smartlyId'));
    setUuid(res.data.body.body);
    setLoadingQRCode(false);
    setLoadingReboot(false);
  }

  useEffect(() => {
    deviceWifi === "connected" ? 
    setLoadingReboot(false) :
    setLoadingReboot(true)
  }, [deviceWifi])

  useEffect(() => {
    getUUIDToApi()
  }, [])
  
  useEffect(()=>{
    if (deviceInfos && deviceInfos.device && (deviceInfos.device["wifi-settings"] || deviceInfos.device.wifiSettings)){
      if(deviceInfos.device.wifiSettings){
        setWifiSettings(
          {
            ssid: deviceInfos.device.wifiSettings.ssid,
            password: deviceInfos.device.wifiSettings.password
          })
      } else {
        setWifiSettings(
          {
            ssid: deviceInfos.device["wifi-settings"].ssid,
            password: deviceInfos.device["wifi-settings"].password
          })
      }
    }
  }, [versionInstalledFirmware])

  return (
    <Col>
      <Card className={styles.card} style={isThermostat(params.get('smartlyId')) ? {height: '950px'} : {}}>
        <DeviceCardHeader deviceName={params.get('deviceName')} smartlyId={params.get('smartlyId')}/>
        <Card.Body className={styles.cardBody} >
          <Row className={styles.cardBodyRow}>
            <Col xs={6} sm={4}>
              <Button
                className={[button.colorWhite, button.width100]}
                onClick={() => handleReboot()}
                disabled={loadingReboot}
              >
                {
                  deviceWifi === "disconnected" ? 'Desconectado' :
                    loadingReboot?
                      <Spinner 
                        style={{height: "20px", width: "20px"}}
                        animation="border" 
                        variant="light" 
                      />
                      : 
                      'Reiniciar'
                }
              </Button>
            </Col>
            <Col xs={6}sm={3}>
              <Form.Switch 
                className={styles.center}
                label={power? 'ON' : 'OFF'}
                onChange={() => setPower(!power)}
                checked={Boolean(power)} 
                data-testid='power'       
              />
            </Col>
            <Col xs={6} sm={4}>
              <Button 
                className={[button.colorWhite, button.width100]}
                onClick={() => setModalOpen(true)}
                disabled={loadingQRCode}
                >
                {loadingQRCode?                      
                  <Spinner 
                    style={{height: "20px", width: "20px"}}
                    animation="border" 
                    variant="light" 
                  />
                  : 
                  'Gerar QR Code'
                  }                
              </Button>
            </Col>
          </Row>
          <Modal show={modalOpen} className={styles.modal}>
            <Modal.Header>
              <Col xs={11}>
                <Modal.Title>QR Code</Modal.Title>
              </Col>
              <Col 
                className={styles.closeModal}
                xs={1} 
                onClick={() => setModalOpen(false)}
              >
                <span className="material-symbols-outlined">close</span>
              </Col>
            </Modal.Header>
            <Modal.Body className={styles.modalBody}>
            <Container>
                <Row>
                  <QRCode id="QRCode" value={uuid} style={{marginBottom: 15}}/>
                </Row>
                <Row>
                  <Col xs={1} className={styles.ColQRcode}>
                    <GetApp
                      style={{
                        fontSize: 35,
                        color: "black",
                      }}
                      onClick={handleQRcodeDownload}
                    />
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
          </Modal>
          {isThermostat(params.get('smartlyId')) ? 
            <ThermostatCardBody />
            :
            <HotClosetCardBody
              deviceWifi={deviceWifi}
              deviceSetPoint={deviceSetPoint} 
              modifySetPoint={modifySetPoint} 
              setModifySetPoint={setModifySetPoint}             
            />
          }
          <Row className={styles.center}>
            <Col sm={5} xs={7} className={styles.textAlignLeft}>
              {`Rede WiFi: ${wifiSettings.ssid}`}
            </Col>
            <Col sm={5} xs={5} className={styles.textAlignRight}>
              {`Senha: ${wifiSettings.password}`}
            </Col>
          </Row>
          <Row className={styles.center}>
            <Col sm={5} xs={7} className={styles.textAlignLeft}>
              Versão do Firmware: 
            </Col>
            <Col sm={5} xs={5} className={styles.textAlignRight}>
              {versionInstalledFirmware}
            </Col>
          </Row>
          <Row className={styles.groupButtons}>
            <Col xs={12} sm={6}>
              <div className={styles.buttons}>
                <Button 
                  className={button.colorWhite}
                  onClick={() => sendJob()}
                  disabled={!updatedFirmware}
                  
                  >
                  {updatedFirmware? 'Atualizar Firmware' : 'Firmware atualizado' }
                </Button>
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <div className={styles.buttons}>
                <Button  
                  className={button.colorWhite}
                  onClick={() => {
                    isThermostat(params.get('smartlyId')) ?
                      handleThermostatUpdateConfig() :
                      handleHotClosetUpdateConfig()
                  }}
                >
                  Salvar Configurações
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card> 
    </Col>      
  );
}

export default DeviceCardConfig;