import { useContext, useState } from 'react';
import { Form, Col, Button } from 'react-bootstrap';

import { Auth } from 'aws-amplify';
import { API } from 'service/Api';

import { SupportContext } from 'context/support';

import styles from './AssistenceForm.module.scss';
import button from 'assets/Button/button.module.scss'
import { AlertContext } from 'context/alert';
import AlertMessage from 'components/AlertMessage';

/**
 * build de page assistence form
 * @returns {React.Component} `component`
 */
function AssistenceForm() {
  const { showAlert, setErrorAlert, setShowAlert, setMessageAlert } = useContext(AlertContext);
  const { getUsersServed, clearUser, clearDevices } = useContext(SupportContext)
  const [describe, setDescribe] = useState('');

  async function submitToApi(){
    if(describe.length < 5) return
    
    const attendantInfos = await Auth.currentUserInfo();
    const attendant = attendantInfos.username;
    const usersServer = getUsersServed();
    
    if(!usersServer || usersServer.length < 1){
      setShowAlert(true);
      setMessageAlert('Nenhum usuário atendido');
      setErrorAlert(true);
      return
    }
    
    const devicesLocalStorage = localStorage.getItem('devices');
    
    if(!devicesLocalStorage || devicesLocalStorage.length < 1){ 
      setShowAlert(true);
      setMessageAlert('Nenhum dispositivo modificado');
      setErrorAlert(true);
      return
    }

      const devices = devicesLocalStorage.split(' ')
      
      try {      
        await API.post('SmartlyAPI', '/reports', {
        body: {
            assistance_user: attendant,
            helped_user: usersServer,
            description: describe,
          devices: devices
        }})
      clearUser();
      clearDevices();
      setDescribe('');
      
      localStorage.removeItem('assistanceStarted');
      window.close();
      
    } catch (error) {
      console.log(error)
    }
  }
  
  return (
    <main>
      <Col sm={10} md={7} xs={12} >
      {showAlert && <AlertMessage />}
        <Form className={styles.form}>
            <Form.Label>Descrição</Form.Label>
            <Form.Control 
              placeholder='Descrição'
              value={describe}
              onChange={(e) => setDescribe(e.target.value)}
              as='textarea'
              size='sm'
              style={{height: '5rem'}}
            />
            {describe.length < 5 && <p style={{color: 'red'}}>Descreva o que ocorreu</p>}            
          <Button 
            className={[button.colorWhite, styles.btn]} 
            onClick={() => submitToApi()}
            disabled={describe.length<5}
            >
              Salvar
            </Button>
        </Form>
      </Col>
    </main> 
   );
}

export default AssistenceForm;