import React, { useContext, useEffect, useState } from 'react';
import { Card, CloseButton, Col, Row, Container, Button, Form, Spinner, Modal } from "react-bootstrap";
import { 
  SignalWifiOffSharp, 
  SignalWifi1BarSharp, 
  SignalWifi2BarSharp,
  SignalWifi3BarSharp,
  SignalWifi4BarSharp,
  GetApp
} from '@material-ui/icons';
import Colors from "assets/Colors";
import Slider from "rc-slider";

import Devices from "context/devices";
import { publish } from 'service/Mqtt';
import { getDeviceUserAndRegDate } from 'service/Device';

import "rc-slider/assets/index.css";
import styles from '../DeviceCard.module.scss';
import button from 'assets/Button/button.module.scss';
import { getUUID } from 'service/Api';
import QRCode from 'react-qr-code';

/**
 * build the Hotcloset component
 * @returns {React.Component} `component`
 */
export default function HotCloset({index, value}) {
  const {device, removeDevice} = useContext(Devices);
  const [user, setUser] = useState('');
  const [regDate, setRegDate] = useState('');
  const [setPoint, updateSetPoint] = useState(value.state.hum_setpoint);
  const [power, setPower] = useState(value.state.power);
  const [modalOpen, setModalOpen] = useState(false);
  const [uuid, setUuid] = useState('');
  const [loadingQRCode, setLoadingQRCode] = useState(true);
  
  const handleQRcodeDownload = () => {
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = value.id;
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };
  
  useEffect(() => {
    setPower(value.state.power);
  }, [device]);

  /**
   * @param {String} status 
   * @param {Number} wifi 
   * @returns {React.Component} receive the status and wifi e build icon wifi
   */
  function getWiFiConnection(status, wifi){
    if(status === "connected"){
      if(wifi === 4){
        return <SignalWifi4BarSharp
          htmlColor={Colors.secondary}
          size={20}
        />
      }else if(wifi === 3){
        return <SignalWifi3BarSharp
          htmlColor={Colors.secondary}
          size={20}
        />
      }else if(wifi === 2){
        return <SignalWifi2BarSharp
          htmlColor={Colors.secondary}
          size={20}
        />
      }else if(wifi === 1){
        return <SignalWifi1BarSharp
          htmlColor={Colors.secondary}
          size={20}
        />
      } 
    }else
      return <SignalWifiOffSharp
        htmlColor={Colors.gray}
        size={20}
      />;
  }

  function sendState(){
    publish(`$aws/things/${value.id}/shadow/name/config/update`, {
      state: { desired: { config: { power: Number(power), hum_setpoint: Number(setPoint) } } }
    });
  }
  
  useEffect(() => {
    async function getInfos(){
      const response = await getDeviceUserAndRegDate(value.id);
      
      if(Object.keys(response).length > 0){
        setUser(response[0].username);
        var date = new Date(response[0].regDate);
        setRegDate(`${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`);
      }
    }

    getInfos();
  }, [value.id])

  async function getUUIDToApi(){
    const res = await getUUID(value.deviceId, value.id)
    setUuid(res.data.body.body);
    setLoadingQRCode(false)
  }

  useEffect(() => {
    getUUIDToApi();
  }, [])

  return (
    <Col key={index} className={styles.devicesCard} sm={5} xs={12}>
      <Card className={styles.card}>
        <Card.Header className={styles.devicesCardHeader}>
          <Card.Title className={styles.devicesCardTitle}>{value.id}</Card.Title>
          <CloseButton 
            className={styles.devicesCardClose}
            onClick={() => removeDevice(value)}
            variant="white"
          />
        </Card.Header>
        <Card.Body className={styles.devicesCardBody}>
          <Col className={styles.devicesCardBodyColumnAround}>
            <Col xs={4}></Col>
            <Col xs={4}>
              <div className={styles.textInfos}>
                <Card.Text className={styles.textInfosTitle}>
                  {`Status`}
                </Card.Text>
                {getWiFiConnection(value.state.status, value.state.wifi)}
              </div>
              </Col>
              <Col xs={4} 
                className={styles.qrCodeButton}
              >
                <Button                
                  className={styles.qrCodeButton}
                  onClick={() => setModalOpen(true)}  
                >
                  {loadingQRCode? 
                  <Spinner 
                    style={{ height: "20px", width:"20px"}}
                    animation='border'
                    variant='light'  
                  /> 
                  : 
                  'Gerar QR Code'
                  }
                </Button>
              </Col>
            </Col>
          <Modal show={modalOpen}>
            <Modal.Header>
              <Col xs={10}>
                <Modal.Title>QR Code</Modal.Title>
              </Col>
              <Col
                className={styles.closeModal}
                xs={1}
                onClick={() => setModalOpen(false)}
                >
                <span className="material-symbols-outlined">close</span>
              </Col>
            </Modal.Header>
            <Modal.Body className={styles.modalBody}>
              <Container>
                <Row>
                  <QRCode id="QRCode" value={uuid} style={{marginBottom: 15}}/>
                </Row>
                <Row>
                  <Col xs={1} className={styles.ColQRcode}>
                    <GetApp
                      style={{
                        fontSize: 35,
                        color: "black",
                      }}
                      onClick={handleQRcodeDownload}
                    />
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
          </Modal>
          <Col className={styles.devicesCardBodyColumnAround}>
            <div className={styles.textInfos}>
              <Card.Text className={styles.textInfosTitle}>
                {`${value.state.operation ? "Temp. Piso" : "Temp. Ambiente"}`}
              </Card.Text>
              {`${value.state.temp}°C`}
            </div>
            <div className={styles.textInfos}>
              <Card.Text className={styles.textInfosTitle}>
                {`Set Point`}
              </Card.Text>
              {`${value.state.hum_setpoint}%`}
            </div>
            <div className={styles.textInfos}>
              <Card.Text className={styles.textInfosTitle}>
                {`Umidade`}
              </Card.Text>
              {`${value.state.hum}%`}
            </div>
          </Col>
          <Col className={styles.devicesCardBodyColumnAround}>
            <div className={styles.textInfos}>
              <Card.Text className={styles.textInfosTitle}>
                {`Calefação`}
              </Card.Text>
              {`${value.state.heating ? "Ligado" : "Desligado"}`}
            </div>
          </Col>
          <Col className={styles.devicesCardBodyColumnAround}>
            <div className={styles.textInfos}>
              <Card.Text className={styles.textInfosTitle}>
                {`Usuário`}
              </Card.Text>
              { user.length > 1 ? user : 'Nenhum usuário cadastrado'}
            </div>
            <div className={styles.textInfos}>
              <Card.Text className={styles.textInfosTitle}>
                {`Data de Registro`}
              </Card.Text>
              {regDate}
            </div>
          </Col>
          <Col className={styles.devicesCardBodyColumnRow}>
            <Slider
              className={styles.slider}
              onChange={(event) => updateSetPoint(event)}
              step={1}
              min={10}
              max={100}
              value={setPoint}
            />
            <Card.Text 
              className={styles.text}
              >{`${setPoint}%`}</Card.Text>
          </Col>
          <Col className={styles.devicesCardBodyColumnRow}>
            <Form.Switch
              className={styles.text}
              bsPrefix="devices-card-switch"
              label="Power"
              checked={Boolean(power)}
              onChange={(event) => setPower(!power)}
            />
          </Col>
          <Button
            className={button.colorWhite}
            onClick={sendState}
          >Enviar</Button>
        </Card.Body>
      </Card>
    </Col>
  );
}