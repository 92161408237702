import { Table } from "react-bootstrap";
import styles from './LogsList.module.scss'

/**
 * build the table with device logs
 * @param {*} param0 
 * @returns {React.Component} `component`
 */
function LargeTable({ logs }) {
  const headerTable = [
    "Estado", 
    "Status Wifi",
    "Wifi",
    "Sensor de Ambiente", 
    "Sensor de Piso", 
    "Consumo", 
    "Data", 
    "Aquecimento", 
    "Umidade", 
    "Erro no sensor", 
    "Set-point", 
    "Tempo ligado",
  ]

  /**
   * return style of the table column 
   * @param {String} header 
   * @returns {Object.key} `object`
   */
  function returnStyle(header){
    if(header === 'Data')
      return [styles.large]
    else if(header === 'Sensor de Piso' || header === 'Erro no sensor' )
      return [styles.middle]
    else if(header.length < 5)
      return [styles.small]
    else
      return [styles.middle]
  }
  
  return ( 
    <>
      <span>{logs[0].device.S}</span>
      <div className={styles.table}>
        <Table>
          <thead className={styles.thead}>
            <tr className={styles.theadRow}>
              {headerTable.map((header) => <td className={returnStyle(header)} key={header}>{header}</td> )}
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {logs.map((log) => 
              <tr className={styles.tbodyRow} key={log.timestamp.N}>
                <td className={styles.middle}>{log.power? (log.power.N === '1'? 'Ligado': 'Desligado') : '-'}</td>
                <td className={styles.middle}>{log.status?  (log.status.S === 'connected'? 'Conectado' : 'Desconectado') : '-'}</td>
                <td className={styles.small}>{log.wifi?  log.wifi.N : '-'}</td>
                <td className={styles.middle}>{log.env_temp? Number(log.env_temp.N).toFixed(1) : '-' }</td>
                <td className={styles.middle}>{log.floor_temp? (log.floor_temp.N === '0' ? 'N consta' : Number(log.floor_temp.N).toFixed(1)) : '-' }</td>
                <td className={styles.middle}>{log.consumption? log.consumption.N : '-' }</td>
                <td className={styles.large}>{log.formated_ts? log.formated_ts.S : '-' }</td>
                <td className={styles.middle}>{log.heating? (log.heating.N === '1'? "Ligado" : "Desligado") : '-' }</td>
                <td className={styles.middle}>{log.humidity? Number(log.humidity.N).toFixed(1) : '-' }</td>
                <td className={styles.middle}>{log.sensorError? (log.sensorError.N === '0'? 'Sem Erro': 'Erro') : '-'}</td>
                <td className={styles.middle}>{log.setPoint?  Number(log.setPoint.N).toFixed(1) : '-'}</td>
                <td className={styles.middle}>{log.time_on?  log.time_on.S : '-'}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </>
   );
}

export default LargeTable;