/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import { API } from "service/Api";
import { isDeviceAdd } from 'utils/Device';

import { InfosDeviceContext } from 'context/device';
import { SupportContext } from 'context/support';

import Routine from 'components/Routine';

import style from "./style.module.scss";
import button from "assets/Button/button.module.scss";

/**
 * build card routine
 * @returns {React.Component} `component`
 */
function CardRoutine({ deviceId, device }) {
  const {
    tempMin,
    tempMax,
    ledIntensity,
    awayTemp,
    gauging,
    prog, 
    setProg 
  } = useContext(InfosDeviceContext);
  const { addDevicesToLocalStorage } = useContext(SupportContext);
  const [routines, setRoutines] = useState({});
  const [ params ] = useSearchParams();
  const userId = params.get('userId');

  const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

  /**
   * filter routine based in day of week
   * @param {String} day 
   * @returns {Object.keys} 
   */
  function filterRoutine(day){
    return routines[day].h.map((value, index) => [value, routines[day].t[index]]);
  }

  /**
   * @param {String} day 
   * @returns {String} receive day in English and return in Portuguese
   */
  function returnDayInPortuguese(day){
    switch (day){
      case 'mon':
        return 'Segunda-Feira';
      case 'tue':
        return 'Terça-Feira';
      case 'wed':
        return 'Quarta-Feira';
      case 'thu':
        return 'Quinta-Feira';
      case 'fri':
        return 'Sexta-Feira';
      case 'sat':
        return 'Sábado';
      case 'sun':
        return 'Domingo';
      default:
        return '';
    }
  }

  /**
   * @param {String} day 
   * @param {Number} i 
   * @returns {React.Component} build component routine
   */
  function buildRoutine(day, i){
    return filterRoutine(day).map((value, index) => 
      <Routine key={index+String(i)} time={value[0]} temp={value[1]} />
    )
  }

  /**
   * send update config  
   */
  async function updateConfig(){
    if(!isDeviceAdd(device))
      addDevicesToLocalStorage(device.id)

    const config = {
      awayTemp: awayTemp,
      ledIntensity: ledIntensity,
      tempRange: [tempMin, tempMax],
      gauging: gauging,
      manual: prog? 1 : 0
    }

    await device.updateAllConfig(userId, params.get('smartlyId'), config)
  }

  useEffect(async () => {
    try{
      const response = await API.get("SmartlyAPI", `/devices/${deviceId}/admin/routine`, {response: true});
      setRoutines(response.data.parameters);
    }catch(e){
      console.log(e);
    }
  }, [deviceId]);

  return (
    <Col>
      <Card className={style.Card}>
        <div className={style.Header}>
          <Card.Title className={style.Title}>
            Rotina 
          </Card.Title>
          <div className={style.Switch}>
            <Form.Switch 
              label={!prog? 'Rotina' : 'Manual'}
              checked={Boolean(!prog)}
              onChange={() => setProg(!prog)}
              />
            <Button
              className={button.colorWhite}
              onClick={() => updateConfig()}
              >
              Alterar Modo
            </Button>
          </div>
        </div>
        <Card.Body className={style.Body}>
          {
            Object.keys(routines).length ?  
              daysOfWeek.map((day, index) => {
                return (
                  <div className={style.Day} key={day}>                    
                    <h3 className={style.day_title}>{ returnDayInPortuguese(day) }</h3>
                      <Row className={style.day_content}>
                        <div>{ buildRoutine(day, index) }</div>
                      </Row>                        
                  </div>                               
                )
              })
              :
              <></>
          }
        </Card.Body>
      </Card>
    </Col>
  );
}

export default CardRoutine;