import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { customPermission } from "service/Authorizarion";
import Logo from "assets/Images/logo_web_app.png";
import styles from './NavBar.module.scss';

/**
 * build the navbar component
 * @returns {React.Component} `component`
 */
export default function NavBar(){
  const navigate = useNavigate();
  const [options, setOptions] = useState([
    {
      title: "Ajuda",
      path: "/main/help"
    }
  ])

  /**
   * verify user permissions and modify navbar by user
   */
  async function getPathForUser(){
    const permission = await customPermission();
    switch (permission){
      case 'admin':
        setOptions([
          {
            title: "Buscar dispositivo",
            path: "/main/searchDevice"
          },
          {
            title: "Buscar usuário",
            path: "/main/users"
          },
          {
            title: "Suporte",
            path: "/main/support/client"
          },
          {
            title: "Cadastrar",
            path: "/main/register"
          },
          {
            title: "Ajuda",
            path: "/main/help"
          }
        ])
        return
  
      case 'support': 
        setOptions([
          {
            title: "Buscar dispositivo",
            path: "/main/searchDevice"
          },
          {
            title: "Buscar usuário",
            path: "/main/users"
          },
          {
            title: "Ajuda",
            path: "/main/help"
          }
        ])
        return

      case 'installer': 
        setOptions([
          {
            title: "Buscar dispositivo",
            path: "/main/searchDevice"
          },
          {
            title: "Ajuda",
            path: "/main/help"
          }
        ])
        return;

      default:
        return;
    }

  }

  useEffect(() => {
    getPathForUser()
  }, [])

  return (
    <>
      <Navbar 
        className={styles.headerContent}
        fixed="top"
        expand="lg"
      >
        <Container className={styles.headerLogo}>
          <Navbar.Brand>
            <img
              className={styles.logo}
              src={Logo}
              alt='Logo Smartly'
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className={styles.navContainer}>
              {
                options.map((item, index) => 
                  <NavLink
                    key={index}
                    className={({isActive}) =>
                    isActive ? styles.navlinkActive : styles.navlink
                  }
                    to={item.path}
                  >
                    {item.title}
                  </NavLink>
                )
              }
              <Nav.Link
                className={styles.navlink}
                onClick={async () => {
                  await Auth.signOut();
                  sessionStorage.removeItem('jwtToken');
                  navigate("/login");
                }}
              >
                Sair
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet/>
    </>);
}