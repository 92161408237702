import React, { useState } from 'react';
import { 
  Container, 
  Form,
  Col,
  Row,
  Alert,
  Button,
  Table,
  Spinner
} from 'react-bootstrap';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { API } from "service/Api";
import { authorization } from 'service/Authorizarion';

import Authorization from 'components/Authorization';
import NotAuthorized from 'components/NotAuthorized';

import styles from "./style.module.scss";
import button from 'assets/Button/button.module.scss';

/**
 * build the search user page
 * @returns {React.Component} `component`
 */
function SearchUser(props) {

  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [username, setUsername] = useState("");
  const [type, setType] = useState("username");
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(
    {
      size: false,
      notFound: false
    }
  );
  const [authorizated, setAuthorizated] = useState(authorization());

  /**
   * get users registered 
   * @param {React.FormEvent} event 
   */
  async function handleSearch(event){
    event.preventDefault();
    setLoad(true);
    try{

      if(username.length < 1){
        setError({size: true, notFound: false});
        throw Error("Username length < 1");
      }

      const response = await API.get(
        "SmartlyAPI",
        `/users/`,
        {
          queryStringParameters:{
            username: username,
            search: type
          }
        }
      );
      setUsers(response);
      setLoad(false);
    }catch(e){
      setError({size: username.length < 1, notFound: username.length >= 4});
      setLoad(false);
    }
  }

  return (
    <>
      <Authorization setAuthorizated={setAuthorizated} />
      {
        !authorizated ? 
        <NotAuthorized />
        :
        <>
          <Form className={styles.search} onSubmit={handleSearch}>
            <Container>
              <h2 className={styles.title}>
                Buscar usuário
              </h2>
              <Col className={styles.center} xs={12}>
                {
                  error.size || error.notFound ? 
                    <Alert 
                      variant="danger"
                    >
                      {
                        error.size ? <>Preencha o campo de texto!<br/></>: ""
                      }
                      {
                        error.notFound ? <>Usuário não encontrado!<br/></> : ""
                      }
                    </Alert>
                  :
                    <></>
                }
              </Col>
              <Row className={styles.center}>
                <Col sm={2} xs={5}>
                  <Form.Select
                    value={type}
                    onChange={(select) => setType(select.target.value)}
                  >
                    <option value="username">Usuário</option>
                    <option value="email">E-mail</option>
                    <option value="name">Nome</option>
                  </Form.Select>
                </Col>
                <Col sm={3} xs={7}>
                  <Form.Control 
                    type="text"
                    placeholder={`Ex: ${type}`}
                    value={username}
                    onChange={(input) => setUsername(input.target.value)}
                  />
                </Col>
              </Row>
              <Row className={styles.center}>
                <Col sm={3} xs={7}>
                  <Button
                    className={[button.colorWhite, button.width100]}
                    type="submit"
                    disabled={load}
                  >
                    {load ? <Spinner animation="border" size="sm"/> : "Buscar"}
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
          <Table 
            bordered hover
            responsive="sm"
          >
            <thead>
              <tr>
                <th>Nome de usuário</th>
                <th>E-mail</th>
                <th>Nome completo</th>
              </tr>
            </thead>
            <tbody>
              {
                users.length ?
                users.map(
                    (value) => 
                      <tr
                        onClick={() => navigate({
                          pathname: "user",
                          search: createSearchParams(
                            {
                              username: value.username,
                              email: value.email,
                              name: value.fullName,
                              userId: value.userId
                            }
                          ).toString()
                        })}
                        key={value.username}
                      >
                        <td>{value.username}</td>
                        <td>{value.email}</td>
                        <td>{value.fullName}</td>
                      </tr>
                  ) :
                  <></>
              }
            </tbody>
          </Table>
        </>
      }
    </>
  );
}

export default SearchUser;