import ProtectPageImport from "./ProtectPage";
import NavBarImport from "./NavBar";
import DeviceListImport from "./DeviceList";
import { Thermostat, HotCloset } from "./DeviceCard";
import CardRegisterImport from "./CardRegister";
import AssistenceFormImport from '../pages/Main/AssistenceForm';

export { Thermostat, HotCloset };
export const DeviceList = DeviceListImport;
export const NavBar = NavBarImport;
export const ProtectPage = ProtectPageImport;
export const CardRegister = CardRegisterImport;
export const AssistenceForm = AssistenceFormImport;